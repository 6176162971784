<template>
  <div class="view pa24">
      <el-page-header @back="goBack" content="解绑记录详情">
      </el-page-header>
      <commonTable
        :tableData="tableData"
        :total="total"
        :currentPage="pageNum"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:table>
          <el-table-column
              prop="className"
              align="center"
              label="产品类别"
          />
          <el-table-column
            prop="handle"
            align="center"
            label="标识"
          >
            <template slot-scope="scope">
              {{scope.row.handle}}
              <span :class="scope.row.type == 3?'name_status1':'name_status'">{{scope.row.type == 3 ? '箱' : '盒'}}</span>
              <span class="copyTxt" @click="copy(scope.row.handle)"><img src="../../assets/images/fz.png" /></span>
            </template>
          </el-table-column>
<!--          <el-table-column-->
<!--            prop="code"-->
<!--            align="center"-->
<!--            :label="labelName"-->
<!--          >-->
<!--          <template slot-scope="scope" v-if="userType == 2">-->
<!--            {{scope.row.code}}-->
<!--          </template>-->
<!--          <template slot-scope="scope" v-else>-->
<!--            {{scope.row.dealerCode}}-->
<!--          </template>-->
<!--          </el-table-column>-->

          <!-- <el-table-column
              prop="nickName"
              align="center"
              label="操作人"
          >
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
         </el-table-column> -->

          <el-table-column
              prop="createBy"
              align="center"
              label="操作人"
          >
         </el-table-column>
          <el-table-column
            prop="createTime"
            align="center"
            label="解绑时间"
          >
          </el-table-column>
          <!-- <el-table-column align="center" width="200" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="boxDetail(scope.row)">查看</el-button>
            </template>
          </el-table-column> -->
        </template>
      </commonTable>
  </div>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import { fetchUnbindDetail } from '@/api/warehouseManage.js'
export default {
    name: "dealerList",
    components: {
      commonTable
    },
    data() {
      return {
          tableData: [],
          pageSize: 10,
          pageNum: 1,
          total: 0,

          batchId:'',
          userType: ''
      };
    },
    created() {
      this.batchId = this.$route.query.id
      this.getList()
      let userInfo = JSON.parse(localStorage.getItem('info'));
      let roleType = userInfo.userType
      this.userType = userInfo.userType
      if(roleType == 3) { // 代理商,获取经销商列表
        this.labelName='经销商'
      }else if(roleType == 4) { // 经销商

      }else {
        this.labelName='代理商'
      }
    },
    methods: {
      goBack() {
        this.$router.push({path:'/crk/unbindLog'})
      },
      copy(context) {
          // 创建输入框元素
          let oInput = document.createElement('input');
          // 将想要复制的值
          oInput.value = context;
          // 页面底部追加输入框
          document.body.appendChild(oInput);
          // 选中输入框
          oInput.select();
          // 执行浏览器复制命令
          document.execCommand('Copy');
          // 弹出复制成功信息
          this.$message.success('复制成功');
          // 复制后移除输入框
          oInput.remove();
      },
      handleSizeChange(num) {
          this.pageSize = num;
          this.pageNum = 1;
          this.getList();
        },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      getList() {
        let params={
          pageNum: this.pageNum,
          pageSize: 10,
          batchId: this.$route.query.id
        }
        fetchUnbindDetail(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
      }
    }

}
</script>
